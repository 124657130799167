import { createGlobalStyle } from "styled-components";





export const CommonStyleMain_R =  createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
	font-family: "Roboto", "Noto Sans KR", sans-serifsnas;
	background-color:#eaeaea;
}
* {
	box-sizing: border-box;
}
img{
    width:100%;
}

:root {
    /*--main-color: #f2cb2c;*/
    --main-color: #479CED;
	--sub-color: #DDF4FC;
	--font-color-title: #333333;
	--font-color-sub:#6E6E6E;
	--scroll-color-back:#eeeeee73;
	--scroll-color:#479CED;
	--dark-font: #333;
	--font-size-title: 1.125em;
	
}
html { scrollbar-arrow-color: var(--scroll-color-back);
    scrollbar-Track-Color: var(--scroll-color-back); 
    scrollbar-base-color: var(--scroll-color-back);
    scrollbar-Face-Color: var(--scroll-color-back);
    scrollbar-3dLight-Color: var(--scroll-color-back);         
    scrollbar-DarkShadow-Color: var(--scroll-color-back);
    scrollbar-Highlight-Color: var(--scroll-color-back);
    scrollbar-Shadow-Color: var(--scroll-color-back)}

/* Chrome, Safari용 스크롤 바 */
::-webkit-scrollbar {width:12px; height: 12px;}
::-webkit-scrollbar-button:start:decrement, 
::-webkit-scrollbar-button:end:increment {display: block; width: 0px; height: 0px; background: url() rgba(0,0,0,.05);}
::-webkit-scrollbar-track {  background:var(--scroll-color-back);}
::-webkit-scrollbar-thumb {  background: var(--scroll-color);  border-radius: 6px;}
.dim{
	display: none;
	position: fixed;
	left:0;
	right: 0;
	top:0;
	bottom: 0;
	z-index: 13;
	background-color: rgba(0,0,0,0.6);
	text-indent: -999999px;
}
.dim.on, .modal.on{
	display:block;
}
.modal{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 14;
}
.modal .modal_table{
    display: table;
    width: 100%;
    height: 100vh;
}
.modal .modal_table .modal_table_cell{
    display: table-cell;
    vertical-align: middle;
    padding: 0 20px;
}
.modal .modal_table .modal_table_cell .modal_table_inner{
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    max-height: 500px;
    overflow: auto;
}
.modal_title{
	position: relative;
}
.modal table{
	width: 100%;
}
.modal table th{
	color: #fff;
	background-color: #666666;
}
#modal_tbody{
	text-align: left;
}
.modal_content{
	padding: 50px 0;
}

span.close{
	position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 30px;
    height: 30px;
	cursor: pointer;
}
span.close::after{
    position: absolute;
    content: "";
    display: block;
    top: 50%;
    left: 0;
    width: 30px;
    height: 2px;
    background-color: var(--dark-font);
    border-radius: 10px;
    transform: rotate(45deg);
}
span.close::before{
    position: absolute;
    content: "";
    display: block;
    top: 50%;
    left: 0;
    width: 30px;
    height: 2px;
    background-color:var(--dark-font);
    border-radius: 10px;
    transform: rotate(-45deg);
}
`;