import { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Link } from "react-router-dom";
import { API, S3 } from "../../config/config";
import { MainStyledR } from "./Styled/MainStyle";
import { AiFillEdit } from "react-icons/ai";
import { FaInstagram,FaInstagramSquare } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
const schedule = [
    {
      time: '08:00',
      firstDay: '',
      secondDay: '기상',
      thirdDay: '기상',
    },
    
    {
      time: '09:00',
      firstDay: '',
      secondDay: '아침식사',
      thirdDay: '아침식사',
    },
    {
      time: '10:00',
      firstDay: '',
      secondDay: '강의 워크샵: 비전과 사명',
      thirdDay: '강의: 크리스천의 직업관(3M)',
    },
    {
      time: '11:00',
      firstDay: '',
      secondDay: '점심식사',
      thirdDay: '워크샵: 비전선포',
    },
    {
      time: '12:00',
      firstDay: '오리엔테이션',
      secondDay: '점심식사',
      thirdDay: '점심식사',
    },
    {
      time: '13:00',
      firstDay: '점심식사',
      secondDay: '수상레포츠',
      thirdDay: '페회예배',
    },
    {
      time: '14:00',
      firstDay: '아이스브레이킹',
      secondDay: '휴식',
      thirdDay: '',
    },
    {
      time: '15:00',
      firstDay: '조별모임<br>(해변 운동 & 조별 미션)',
      secondDay: '토크콘서트',
      thirdDay: '',
    },
    {
      time: '16:00',
      firstDay: '',
      secondDay: '이랜드그룹 입지원 전략/취업',
      thirdDay: '복귀',
    },
    {
      time: '17:00',
      firstDay: '',
      secondDay: '저녁식사',
      thirdDay: '',
    },
    {
      time: '18:00',
      firstDay: '',
      secondDay: '저녁식사',
      thirdDay: '저녁식사',
    },
    {
      time: '19:00',
      firstDay: '',
      secondDay: '나의 인생스토리',
      thirdDay: '찬양예배',
    },
    {
      time: '20:00',
      firstDay: '',
      secondDay: '개인시간',
      thirdDay: '',
    },
    {
      time: '21:00',
      firstDay: '',
      secondDay: '개인시간',
      thirdDay: '개인시간',
    },
    {
        time: '22:00',
        firstDay: '',
        secondDay: '개인시간',
        thirdDay: '개인시간',
      },
  ];

  const schedule2 = [
    {
        startTime: '12:00',
        endTime : '13:00',
        title : "오리엔테이션",
        day : "08/29"
    },
    {
        startTime: '13:00',
        endTime : '14:30',
        title : "점심식사",
        day : "08/29"
    },
    {
        startTime: '14:30',
        endTime : '16:00',
        title : "아이스브레이킹",
        day : "08/29",
        img : "schedule_02.jpg"
    },
    {
        startTime: '16:00',
        endTime : '18:00',
        title : "<span>조별모임<br><span class=\"light-txt_schedule\">해변 운동 & 조별 미션</span></span>",
        day : "08/29",
        img : "schedule_03.jpg"
    },
    {
        startTime: '18:00',
        endTime : '19:00',
        title : "저녁식사",
        day : "08/29"
    },
    {
        startTime: '19:00',
        endTime : '21:00',
        title : "<span>워크샵<br><span class=\"light-txt_schedule\">나의 인생스토리</span></span>",
        day : "08/29",
        img : "schedule_04.jpg"
    },
    {
        startTime: '21:00',
        endTime : '22:00',
        title : "개인시간 및<br>취침",
        day : "08/29"
    },




    {
        startTime: '08:00',
        endTime : '08:30',
        title : "기상",
        day : "08/30",
        img : "schedule_08.jpg"
    },

    {
        startTime: '08:30',
        endTime : '09:30',
        title : "아침식사",
        day : "08/30"
    },
    {
        startTime: '09:30',
        endTime : '12:00',
        title : "<span>강의 워크샵<br><span class=\"light-txt_schedule\">비전과 사명</span></span>",
        day : "08/30",
        img : "schedule_05.jpg"
    },
    {
        startTime: '12:00',
        endTime : '13:30',
        title : "점심식사",
        day : "08/30"
    },
    {
        startTime: '13:30',
        endTime : '15:30',
        title : "수상레포츠",
        day : "08/30",
        img : "schedule_01.jpg"
    },
    {
        startTime: '15:30',
        endTime : '16:00',
        title : "휴식",
        day : "08/30"
    },
    {
        startTime: '16:00',
        endTime : '18:00',
        title : `<span>토크콘서트<br><span class=\"light-txt_schedule\">이랜드그룹 <br class=\"displayNone\">임직원<br class=\"displayNone\"> 진로/취업</span></span>`,
        day : "08/30",
        img : "schedule_06.jpg"
    },
    {
        startTime: '18:00',
        endTime : '19:00',
        title : "저녁식사",
        day : "08/30"
    },
    {
        startTime: '19:00',
        endTime : '21:00',
        title : "찬양예배",
        day : "08/30",
        img : "schedule_07.jpg"
    },
    {
        startTime: '21:00',
        endTime : '22:00',
        title : "<span>개인시간 및<br>취침</span>",
        day : "08/30"
    },


    {
        startTime: '08:00',
        endTime : '08:30',
        title : "기상",
        day : "08/31",
        img : "schedule_08.jpg"
    },
    {
        startTime: '08:30',
        endTime : '09:30',
        title : "아침식사",
        day : "08/31"
    },
    {
        startTime: '09:30',
        endTime : '11:00',
        title : "<span>강의<br><span class=\"light-txt_schedule\">크리스천의 <br class=\"displayNone\">직업관(3M)</span></span>",
        day : "08/31",
        img : "schedule_05.jpg"
    },
    {
        startTime: '11:00',
        endTime : '12:00',
        title : "워크샵 <br>비전선포",
        day : "08/31"
    },
    {
        startTime: '12:00',
        endTime : '13:30',
        title : "점심식사",
        day : "08/31"
    },
    {
        startTime: '13:30',
        endTime : '14:30',
        title : "폐회예배",
        day : "08/31",
        img : "schedule_07.jpg"
    },
    {
        startTime: '14:30',
        endTime : '22:00',
        title : "복귀",
        day : "08/31"
    },
  ];


export const Main_R = ()=>{

    
    const mainRef= useRef(null);
    const [height, setHeight] = useState(0);
    const swiperRef = useRef(null);
    const dataTableRef = useRef(null);
   
    

    useEffect(()=>{
        const main = mainRef.current;
        const heights = main.clientHeight;
        setHeight(heights);
    }, [mainRef]);

    useEffect(()=>{
        const table = dataTableRef.current;
        if (table) {
            const headerCells = table.querySelectorAll('th');
            
            // Create a map to track added time intervals
            const addedIntervals = new Map();
            
            schedule2.forEach((item) => {
              const { day, startTime, endTime, title } = item;
              const startHour = parseInt(startTime.split(':')[0], 10);
              const startMinute = parseInt(startTime.split(':')[1], 10);
              const endHour = parseInt(endTime.split(':')[0], 10);
              const endMinute = parseInt(endTime.split(':')[1], 10);
              
              // Find the header cell corresponding to the day in schedule2
              const headerCell = Array.from(headerCells).find(cell => cell.getAttribute('data-day') === day);
              
              if (headerCell) {
                const columnIndex = Array.from(headerCell.parentNode.children).indexOf(headerCell);
                
                // Process the corresponding column (td elements in the same index column)
                const rows = table.querySelectorAll('tbody tr');
                rows.forEach((row) => {
                  const cells = row.querySelectorAll('td');
                  const td = cells[columnIndex];
                  
                  if (td) {
                    const dataTimeAttr = td.getAttribute('data-time');
                    if (dataTimeAttr) {
                      const dataHour = parseInt(dataTimeAttr.split(':')[0], 10);
                      const dataMinute = parseInt(dataTimeAttr.split(':')[1], 10);
                      
                      // Convert times to minutes for easier comparison
                      const startTimeMinutes = startHour * 60 + startMinute;
                      const endTimeMinutes = endHour * 60 + endMinute;
                      const dataTimeMinutes = dataHour * 60 + dataMinute;
                      
                      // Check if dataTime is within the startTime and endTime range
                      if (dataTimeMinutes >= startTimeMinutes && dataTimeMinutes <= endTimeMinutes) {
                        // Generate a unique key for this time interval
                        const intervalKey = `${day}-${startTime}-${endTime}`;
                        
                        // Check if this interval has already been added
                        if (!addedIntervals.has(intervalKey)) {
                          // Calculate the position and height of the div based on the time interval
                          const totalMinutes = endTimeMinutes - startTimeMinutes;
                          
                          // Calculate the top position based on the start minute within the hour
                          const topOffsetMinutes = startMinute;
                          const topOffsetPercentage = (topOffsetMinutes / 60) * -100; // Percentage of the hour
                          
                          // Calculate the height of the div based on header cell height and total minutes
                          const divHeight = (td.clientHeight / 60) * totalMinutes;
                          
                          // Create a div element
                          const div = document.createElement('div');
                          div.style.position = 'absolute';
                          div.style.top = `${topOffsetPercentage}%`;
                          div.style.left = '0';
                          div.style.width = '100%';
                          div.style.height = `${divHeight}px`; // Set height based on the time duration
                          div.style.backgroundColor = 'transparent';
                          div.style.color = '#333';
                          div.style.borderRadius = "5px";
                          div.style.pointerEvents = 'none';
                          div.innerHTML = `${item.title}`; // Example: Display time range
                          div.style.overflow = 'hidden'; // Hide overflowing text
                          div.style.textAlign = 'center'; // Center text
                          div.style.border = "2px solid #333";
                          if(item.img != null){
                            div.style.background = `url(${S3}/sunhan/view/${item.img}) no-repeat center center`;
                            div.style.backgroundSize = 'cover';
                            div.style.fontWeight = 900;
                            div.style.color = "#fff";
                            div.style.textShadow = "rgb(51, 51, 51) 3px 1px 1px";
                            div.classList.add("back_box");
                            //div.innerText="";
                            //div.innerHTML = `<div class='back_box'></div>`
                           // div.setAttribute("data-text", item.title);
                          }
                          div.classList.add("schedule_box");
                          td.appendChild(div);
                          // Add interval key to the map to prevent duplicates
                          addedIntervals.set(intervalKey, true);
                        }
                      }
                    }
                  }
                });
              }
            });
          }
    }, [dataTableRef]);


   

    /*const handleDownload = async () => {
        const userConfirmed = window.confirm('스케줄을 다운로드 하시겠습니까?');
    
        if (userConfirmed) {
          try {
            const response = await fetch(`${S3}/sunhan/schedule.jpg`, {
              method: 'GET',
              headers: {
                'Content-Type': 'image/jpeg',
              },
            });
    
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
    
            const link = document.createElement('a');
            link.href = url;
            link.download = 'schedule.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
          }
        }
      };*/

    const handleDownload = () => {
        const userConfirmed = window.confirm('스케줄을 다운로드 하시겠습니까?');
        
        if (userConfirmed) {
            const link = document.createElement('a');
            link.href = `${process.env.PUBLIC_URL}/image/schedule.jpg`;
            link.download = 'schedule.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    

    


    return <MainStyledR>
        
        <div id="main" ref={mainRef}>
            <div className="shortcuts">
                <a href="https://forms.gle/2HXZXdqX5ekhFiue7" target="_blank">
                <span>지금 바로 신청하기</span><AiFillEdit />
                </a>
            </div>
            <div className="main_cover">
                <div className="txt_main">
                    <h1>VISION CAMP <span></span></h1>
                    <p className="title_main">일상을 회복하고 비전을 찾는 <br className="displayNone" /><span className="txt_liner">크리스천 멘티</span><span className="txt_color">힐링캠프</span></p>
                    <p className="sub_title">땅은 형태가 없고 비어 있었으며 어둠이 깊은 물 위에 있었고<br></br><span>-창세기 1:2-</span></p>
                </div>
            </div>

            <div className="main_info">
                <div className="main_info_inner">
                    <div className="main_info_inner_cover">
                        <div className="main_info_img">
                            <img src={`${S3}/sunhan/keyvisual01.jpg`}></img>
                        </div>

                        <div className="main_info_txt">
                            <ul>
                                <li><span className="title">대상</span><span>|</span> <span className="txt-value" style={{"lineHeight": "18px"}}>자립준비청년, 탈북 청년, 중도퇴소청년 중 <br></br>
                                기독교 청년 또는 기독교에 관심있는 청년</span></li>
                                <li><span className="title">일시</span><span>|</span> <span className="txt-value">2024.08.29(목)-2024.08.31(토)</span></li>
                                <li><span className="title">나이</span><span>|</span> <span className="txt-value">20세 ~ 32세 <span className="light-txt">(2005년생~1992년생)</span></span></li>
                                <li><span className="title">장소</span><span>|</span> <span className="txt-value" style={{"lineHeight": "18px"}}>강원도 고성 켄싱턴리조트 설악비치<br></br><span className="light-txt">(강원도 고성군 토성면 동해대로4800)</span></span></li>
                                <li><span className="title">모집인원</span><span>|</span> <span className="txt-value">30명 <span className="light-txt">(선착순)</span></span></li>
                                <li><span className="title">참가비</span><span>|</span> <span className="txt-value">후원기관 전액지원</span></li>
                                <li><span className="title">기타</span><span>|</span> <span className="txt-value">예약금 3만원 <span className="light-txt">(캠프 일정 완료시 반환)</span></span></li>
                                <li><span className="title">문의</span><span>|</span> 
                                    <ul>
                                        <li>
                                            <span className="txt-value">
                                            <a className="link" href="https://www.instagram.com/koreaorphanslove/">
                                            <FaInstagramSquare className="instagram" />@koreaorphanslove</a>
                                            </span>
                                        </li> 
                                        <li>
                                            <span className="txt-value">
                                            <a className="link" href="tel:070-7620-3163">
                                            <IoCall className="call" />070-7620-3163</a>
                                            </span>
                                        </li> 
                                    </ul>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="main_info_inner2">
                    <h4 className="title">후원기관</h4>
                    <ul>
                        <li>
                            <a href={`https://www.elandcsr.or.kr/`} target="_bank">
                                <img src={`${S3}/sunhan/group/eLand.png`} alt="이랜드 재단"></img>
                            </a>
                        </li>
                        <li>
                            <a href={`http://www.goodhaven.or.kr/`} target="_bank">
                                <img src={`${S3}/sunhan/group/sunhan.png`} alt="선한울타리"></img>
                            </a>
                        </li>
                        <li>
                            <a href={`http://orphans.kr/`} target="_bank">
                                <img src={`${S3}/sunhan/group/korea.png`} alt="한국고아사랑협회"></img>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="main_cover main_start">
                <div className="txt_main txt_left">
                    <h2>ABOUT <span></span></h2>
                    <p className="sub_title font_weight">여행지를 소개합니다.</p>
                </div>

                <div className="main_info_inner">
                    <dl className="main_view">
                        <dt>
                            <p><img src={`${S3}/sunhan/view/MainView01.jpg`} alt="설악해변 켄싱턴리조트"></img></p>
                            <div className="main_view_cover">

                                <p><img src={`${S3}/sunhan/view/MainView02.jpg`} alt="설악해변 켄싱턴리조트 야경"></img></p>
                                <p><img src={`${S3}/sunhan/view/MainView04.png`} alt="설악해변 켄싱턴리조트 야경"></img></p>
                            </div>
                        </dt>
                        <dd>
                            <h5><span>켄싱턴리조트 설악비치</span></h5>
                            <p>켄싱턴리조트 설악비치에서 진행될 비전캠프에서 좋은 추억을 만들고 하나님을 더 깊이 알아가는 시간을 가지려합니다.
                                캠프 기간 동안 다양한 활동과 프로그램을 통해 멘티들의 삶에 긍정적인 영향을 미치며, 
                                서로를 이해하고 배려하는 마음을 키우고 앞으로도 지속적인 관계 형성과 영적 성장의 기회가 될 것으로 기대됩니다.</p>
                        </dd>
                    </dl>
                    <div className="phone_container">

                        <div className="phone_back">
                            <img src={`${S3}/sunhan/view/PhoneDesign.png`} alt="설악해변 켄싱턴리조트 야경"></img>
                        </div>
                        <div className="phone_slider">
                           {/* <div className="image_border" style={
                                {
                                    "borderImage" : `url(${S3}/sunhan/view/cellphone.png) 0 fill / 0 / 232px 124px`,
                                    "WebkitBorderImage": `url(${S3}/sunhan/view/cellphone.png) 0 fill / 0 / 232px 124px`,
                                    "MozBorderImage": `url(${S3}/sunhan/view/cellphone.png) 0 fill / 0 / 232px 124px`
                                }
                            }></div>*/}

                            <div className="image_border" ></div>
                            <div className="slider_box" data-border={`${S3}/sunhan/view/cellphone.png`}>
                                <Swiper
                                    modules={[Autoplay]}
                                    autoplay={{ delay: 1500, disableOnInteraction: false }}
                                    ref={swiperRef}
                                    slidesPerView={1}
                                    loop={true}
                                    onSwiper={swiper => swiperRef.current = swiper}>
                                    <SwiperSlide style={{"background" : `url(${S3}/sunhan/view/phoneView01.jpg) no-repeat center center`, "backgroundSize": "cover"}}></SwiperSlide>
                                    <SwiperSlide style={{"background" : `url(${S3}/sunhan/view/phoneView02.jpg) no-repeat center center`, "backgroundSize": "cover"}}></SwiperSlide>
                                    <SwiperSlide style={{"background" : `url(${S3}/sunhan/view/phoneView03.png) no-repeat center center`, "backgroundSize": "cover"}}></SwiperSlide>
                                    <SwiperSlide style={{"background" : `url(${S3}/sunhan/view/phoneView04.png) no-repeat center center`, "backgroundSize": "cover"}}></SwiperSlide>
                                    <SwiperSlide style={{"background" : `url(${S3}/sunhan/view/phoneView05.png) no-repeat center center`, "backgroundSize": "cover"}}></SwiperSlide>
                                </Swiper>
                                
                            </div>
                        </div>
                    </div>

                    <ul className="img_info_txt">
                        <li>
                            <dl>

                                <dt>
                                    <Link to={`${S3}/sunhan/view/schedule_04.jpg`} target="_blank" style={{"background" : `url(${S3}/sunhan/view/schedule_04.jpg) no-repeat center center`, "backgroundSize": "cover"}}></Link>
                                </dt>
                                <dd>
                                    <h5>워크샵</h5>
                                    <p>우리의 이야기로 함께 그려나가는 시간을 가지고 서로의 경험을 나누며 함께 성장하는 시간을 가지려 합니다.</p>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>

                                <dt>
                                    <Link to={`${S3}/sunhan/view/schedule_01.jpg`} target="_blank" style={{"background" : `url(${S3}/sunhan/view/schedule_01.jpg) no-repeat center center`, "backgroundSize": "cover"}}></Link>
                                </dt>
                                <dd>
                                    <h5>수상레포츠</h5>
                                    <p>무더운 여름!!! 물놀이와 수상스포츠를 통해 팀워크와 협력심을 기르고, 서로를 응원하며 즐거운 추억을 만들어 봅시다.</p>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>

                                <dt>
                                    <Link to={`${S3}/sunhan/view/schedule_06.jpg`} target="_blank" style={{"background" : `url(${S3}/sunhan/view/schedule_06.jpg) no-repeat center center`, "backgroundSize": "cover"}}></Link>
                                </dt>
                                <dd>
                                    <h5>토크 콘서트 진로/취업</h5>
                                    <p>참여자 모두가 자신의 경험을 나누고, 상호 협력하여 새로운 미래를 그려나갈 수 있도록 지원하겠습니다.</p>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>

                                <dt>
                                    <Link to={`${S3}/sunhan/view/schedule_07.jpg`} target="_blank" style={{"background" : `url(${S3}/sunhan/view/schedule_07.jpg) no-repeat center center`, "backgroundSize": "cover"}}></Link>
                                </dt>
                                <dd>
                                    <h5>예배시간</h5>
                                    <p>우리의 시간, 물질, 만남을 온전히 주님에게 맡기며 기도하고 찬양하는 시간입니다.</p>
                                </dd>
                            </dl>
                        </li>
                        
                    </ul>
                </div>



            </div>



            <div className="main_cover main_start" style={{"padding-top": "0"}}>
                <div className="txt_main txt_left">
                    <h2>SCHEDULE <span></span></h2>
                    <p className="sub_title font_weight">우리의 일정은?</p>
                </div>
                <div className="main_info_inner">
                    <table className="vision-camp-schedule" ref={dataTableRef}>
                        <thead>
                            <tr>
                            <th>시간</th>
                            <th data-day="08/29">첫째 날 (08/29)</th>
                            <th data-day="08/30">둘째 날 (08/30)</th>
                            <th data-day="08/31">셋째 날 (08/31)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedule.map((item, index) => (
                            <tr key={index}>
                                <td>{item.time}</td>
                                <td data-time={item.time}></td>
                                <td data-time={item.time}></td>
                                <td data-time={item.time}></td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="schedule_download">
                        <button type="button" onClick={handleDownload}>스케줄 다운로드</button>
                    </div>

                    
                </div>
            </div>
            <div className="pc_short">
                <a href="https://forms.gle/2HXZXdqX5ekhFiue7" target="_blank">
                <span>지금 바로 신청하기</span><AiFillEdit />
                </a>
            </div>
        </div>
    </MainStyledR>
}