import styled from "styled-components";
import { S3 } from "../../../config/config";


export const InquiryStyle = styled.div`

.inquiry .title{
    padding-bottom: 20px;
}

.inquiry {
    width: 100%;
    background: url('${S3}/sunhan/contact_background.jpg') no-repeat center center;
    background-size: cover;
    padding: 100px 25px;
    font-size: 1em;
}
.inquiry .inquiry_inner{
    max-width: 1200px;
    margin:0 auto;
}
.inquiry .inquiry_inner .title h3{
    color:#fff;
    text-align:left;
}
.inquiry .inquiry_inner .title span{
    color: #fff;
    
}
.inquiry .inquiry_inner .enquiry {
    text-align: center;
    font-size: 1em;
}
.inquiry .inquiry_inner .enquiry input[type=text], input[type=number] {
    padding: 20px 20px;
    font-size: 0.875em;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: none;
    outline: 0;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
::placeholder {
    color: #cccccc;
    font-style:italic;
}
textarea::placeholder {
    color: #cccccc;
    font-family: "Open Sans", sans-serif;
    padding: 7px 13px;
    font-size: 0.875em;
}
.inquiry .inquiry_inner .enquiry input[type=email]{
    padding: 20px 20px;
    font-size: 0.875em;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: none;
    outline: 0;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.inquiry .inquiry_inner .enquiry input[type=submit]{
    clear: both;
    display: block;
    padding: 20px 60px;
    font-size: 0.875em;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    background-color: #603d99;
    border: none;
    outline: 0;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 auto;
    font-weight:500;
}
.inquiry .inquiry_inner .enquiry .name{
    float: left;
    width: 47%;
} 
.inquiry .inquiry_inner .enquiry .email {
    float: left;
    clear: both;
    margin-top: 28px;
    width: 47%;
}
.inquiry .inquiry_inner .enquiry .phone {
    float: left;
    clear: both;
    width: 47%;
    margin-top: 28px;
    margin-bottom: 28px;
}
.inquiry .inquiry_inner .enquiry textarea {
    width: 50%;
    height: 237px;
    border: 1px solid #4acaa9;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 18px;
    resize: none;
}

@media only screen and (max-width: 1000px){
    .inquiry .inquiry_inner .enquiry .name,
    .inquiry .inquiry_inner .enquiry .email,
    .inquiry .inquiry_inner .enquiry .phone,
    .inquiry .inquiry_inner .enquiry textarea{
        float: none;
        width: 100%;   
    }
    .inquiry .inquiry_inner .enquiry input[type=submit]{
        margin-top: 15px;
        width: 100%;
    }
    
}


`; 