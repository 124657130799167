import styled from "styled-components";
import { S3 } from "../../../config/config";


export const MainStyledR = styled.div`
h1{
    text-align:center;
    text-shadow: 1px 5px 6px #333;
    color:var(--main-color);
}
h2{
    text-align:left;
    text-shadow: 1px 5px 6px #333;
    color:var(--main-color);
    display:inline-block;
    position:relative;
}
br.displayNone{
    display:none;
}
#main{
	display:flex;
	margin:0 auto;
	width:100%;
	min-height:100vh;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    padding:50px 0;
    position:relative;
}
#main .main_cover{
	width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    padding: 50px 0;
}
#main .title_main{
    padding-top: 50px;
}
#main .main_cover .txt_main{
    padding: 22px;
	width:100%;
	text-align:center;
	box-sizing: border-box;
	font-weight:700;
    font-size:26px;
	color:#000;
    position:relative;
}

#main .main_cover .txt_main.txt_left{
    text-align:left;
}
#main p span.txt_liner {
    position:relative;
	z-index:1;
}

#main p span.txt_liner::before{
	content:"";
	position:absolute;
	display:block;
	top:30%;
	left:0;
	width:100%;
	height:50%;
	background-color:var(--sub-color);
	z-index:-1;
}
#main p span.txt_color {
    color:var(--main-color);
    text-shadow: 12px 29px 82px #000;
    font-weight:900;
}
.main_info{
    background: linear-gradient(to right, #fff 85%, #eaeaea 50%);
    width: 100%;
}
.main_info .main_info_inner{
    padding: 30px; 25px;
    
}
.main_info .main_info_inner .main_info_inner_cover{
    display: flex;
    max-width:1200px;
    margin: 0 auto;
}
h1 span,
h2 span{
	position:absolute;
	right: 50%;
    top: -30px;
	-moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
    display: inline-block;
}

h2 span{
    right: -50px;
    top: -15px;
}
h1 span::before,
h2 span::before{
	content: '';
	speak-as: never;
    display: inline-block;
    text-decoration: inherit;
    width: 19px;
	height:19px;
    margin-right: .2em;
    text-align: center;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
	background-color:transparent;
	border:2px solid var(--main-color);
	border-radius:50%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.main_info_img{
    width: 100%;
    margin-top:-46px;
}
.main_info_txt{
    margin-left: 50px;
}
.main_info_txt li .title,
h4.title{
	font-size:1.613em;
	color:var(--main-color);
	position: relative;
	font-weight:500;
	box-sizing:border-box;
    
    display:flex;
    align-items:center;
    min-width:47px;
}
.light-txt{
    color:#666;
    font-weight:500;
}
.light-txt_schedule{
    color:#fff;
    font-weight:300;
    font-size:12px;
}
.main_info_txt li .title::before,
h4.title::before{
	content: "";
    display: inline-block;
    margin-right: 10px;
    width: 5px;
    height: 22.5px;
    background-color: var(--main-color);
}
.main_info_txt li{
    display: inline-flex;
    align-items: center;
    width:100%;
    gap:5px;
    padding: 10px 0;
    /*line-height:50px;*/
}
.main_info_txt li span.txt-value{
    
}
.main_info_inner2{
    max-width:1200px;
    margin: 0 auto;
    padding:0 30px; 
}

.main_info_inner2 ul{
    display:flex;
    align-items:center;
    padding: 25px 0;
}
.main_info_inner2 ul li{
    margin-right: 25px;
}
.main_info_inner2 ul li:last-child{
    margin-right:0;
}
.main_info_inner2 ul li a{
    width:180px;
    display:inline-flex;
}

p.sub_title{
    color: #666;
    font-size: 12px;
    font-weight: 500;
    padding: 20px 0 0;
}
p.sub_title.font_weight{
    font-size: 22px;
}
p.sub_title span{
    display:block;
    width:100%;
    text-align:center;
}
#main .main_cover.main_start{
    padding: 100px 0;
}


.main_view{
    display:flex;
}
.main_info_inner{
    padding: 0 50px 100px 50px;
}
.main_view dt{
    width:100%;
}
.main_view dd{
    margin-left: 50px;
    text-align:left;
    padding-top:50px;
}
.schedule_download{
    padding-top:30px;
    text-align:center;
}
.schedule_download button[type="button"]{
    cursor:pointer;
    background-color : var(--main-color);
    padding: 10px 5px;
    border-radius: 5px;
    color: #fff;
}
.main_view dd h5{
	display: block;
    font-size: 30px;
    font-weight: 700;
    color: #000;
    padding: 10px 0;
    position: relative;
}
    
.main_view dd h5 span{
    position: relative;
}
.main_view dd h5 span::before{
    content: "";
    display: block;
    position: absolute;
    left: -12px;
    top: -5px;
    width: 20px;
    height: 6px;
    background-color: var(--main-color);
    transform: rotate(-45deg);
}
.main_view dd p,
.img_info_txt dd p{
    font-weight: 300;
    color: #666;
}
.main_view_cover{
    display:flex;
}
.main_view img{
    border: 3px solid #fff;
}
.main_details_view{
    display:flex;
    padding-top:50px;
}
.main_details_view li{
    margin-left: 10px;
    width: calc((100% - 40px)/5);
}
.main_details_view li:first-child{
    margin-left:0;
}





.phone_back{
	width:223px;
	float:left;
	clear:both;
    padding-top: 100px;
}

.slider_box{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
}

.slider_box::before{
    content: '';
    position: absolute;
    left: -6px;
    top: -14px;
    width: 106%;
    height: 27px;
    background-image: url(${S3}/sunhan/view/cellphone.png);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 100;
}
.slider_box::after{
    content: '';
    position: absolute;
    left: -6px;
    bottom: -14px;
    width: 106%;
    height: 27px;
    background: url(${S3}/sunhan/view/cellphone.png) no-repeat center bottom;
    background-size: cover;
    z-index: 100;
}

.phone_slider .image_border{
	position: absolute;
    left: -6px;
    top: -14px;
    width: 28px;
    height: 475.3px;
    background: url(${S3}/sunhan/view/cellphone.png) no-repeat left top;
    background-size: cover;
    z-index: 100;
}
.phone_slider .image_border::before{
    content: '';
    position: absolute;
    right: -208.4px;
    top: -0.4px;
    width: 28px;
    height: 100%;
    background: url(${S3}/sunhan/view/cellphone.png) no-repeat right top;
    background-size: cover;
    z-index: 100;
}
.phone_container{
    display:flex;
    justify-content:center;
}
.phone_slider{
	position:relative;
	width:223px;
	height:447.47px;
	float:left;
	margin-left:50px;
	margin-top:200px;
    z-index: 100;
}
    /*
.phone_slider .image_border{
	position:relative;
	margin:0 auto;
	margin-top:223.735px;
	width:1px;
	height:1px;
	z-index:3;
    
}*/


.swiper-horizontal{
    overflow:hidden;
    border-radius:5px;
}
.swiper-wrapper{
    height:100%;
    z-index:-1;
}
.swiper-wrapper .swiper-slide {
	text-align: center;
    background-size:cover;
    height:100%;
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}
.swiper-horizontal{
    height:100%;
}
.text3{
	position:relative;
	float: left;
    width: 30%;
    margin-left: 50px;
    margin-top: 250px;
}
.text3 span.span1{
	width:100%;
}
@keyframes spin {
	0%{
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100%{
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

/* 테이블 스타일 */
table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

/* 테이블 헤더 스타일 */
th {
  background-color: #f2f2f2;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

/* 테이블 셀 스타일 */
td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  position:relative;
  height:80px;
}

/* 홀수 행 스타일 */
tr:nth-child(odd) {
  background-color: #f2f2f2;
}

/* 마우스 오버 시 스타일 */
tr:hover {
  background-color: #e6e6e6;
}

/* 일정표 스타일 */
.schedule-table th, .schedule-table td {
  text-align: center;
}

.schedule-table th {
  background-color: #4CAF50;
  color: white;
}

.schedule-table td {
  padding: 15px;
  position:relative;
}

.schedule-table .time-col {
  width: 20%;
}

.schedule-table .event-col {
  width: 40%;
}

.schedule-table .location-col {
  width: 20%;
}

.schedule-table .action-col {
  width: 20%;
}

table, tr, td {
      z-index: 1;
    }
.schedule_box{
    display:flex;
    font-size:18px;
    align-items:center;
    justify-content:center;
    z-index:10;
    cursor:pointer;
}
.schedule_box.back_box::before{
    content: "";
    display:flex;
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.5);
    align-items:center;
    justify-content:center;
    z-index: -1;
}

.img_info_txt{
    display:flex;
    justify-content:space-between;
    gap:10px;
    padding-top:80px;
}


.img_info_txt li dt{
    width:100px;
    height:100px;
    border-radius: 50px;
    overflow:hidden;
    margin: 0 auto;
}
.img_info_txt li dd{
    text-align:center;
    margin-top:10px;
}
.img_info_txt li a{
    display:block;
    height:100%;
}

.shortcuts{
    
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 18px;
    top: calc((100% - 180px) / 2);
    width: 50px;
    height: 50px;
    padding: 0 15px;
    text-align: center;
    border-radius: 5px;
    color: #333;
    background-color: var(--main-color);
    z-index: 999;
    cursor:pointer;
    box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, .2);
    transition: width 0.3s;
    
}
.shortcuts span{
    text-indent: -99999px;
    display: inline-block;
    opacity:0;
}

.instagram {
    color:#FF027B;
    font-size: 22px;
    vertical-align:middle;
    margin-right:5px;
}
.call{
    color:#333;
    font-size: 22px;
    vertical-align:middle;
    margin-right:5px;
}

.link{
    text-decoration: underline;
    color:blue;
}

.pc_short{
    
    display: inline-flex;
    justify-content: center;
    align-items: center;
    
    height: 50px;
    padding: 0 15px;
    text-align: center;
    border-radius: 5px;
    color: #333;
    background-color: var(--main-color);
    z-index: 999;
    cursor:pointer;
    box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, .2);
    transition: width 0.3s;
}
.pc_short a{
    color:#fff;
}

.main_info_txt li li{
    padding: 3px 0;
}
.main_info_txt li ul{
    padding : 0;
    margin-left: 10px;
}
.inq_btn{
    display: inline-block;
    padding: 10px 5px;
    font-size: 0.875em;
    color: #fff;
    background-color: var(--main-color);
    border: none;
    outline: 0;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    /* margin: 0 auto; */
    font-weight: 500;
}
.inquiry .title{
    padding-bottom: 20px;
}

.inquiry {
    width: 100%;
    background: url('${S3}/sunhan/contact_background.jpg') no-repeat center center;
    background-size: cover;
    padding: 100px 25px;
    font-size: 1em;
}
.inquiry .inquiry_inner{
    max-width: 1200px;
    margin:0 auto;
}
.inquiry .inquiry_inner .title h3{
    color:#fff;
    text-align:left;
}
.inquiry .inquiry_inner .title span{
    color: #fff;
    
}
.inquiry .inquiry_inner .enquiry {
    text-align: center;
    font-size: 1em;
}
.inquiry .inquiry_inner .enquiry input[type=text], input[type=number] {
    padding: 20px 20px;
    font-size: 0.875em;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: none;
    outline: 0;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
::placeholder {
    color: #cccccc;
    font-style:italic;
}
textarea::placeholder {
    color: #cccccc;
    font-family: "Open Sans", sans-serif;
    padding: 7px 13px;
    font-size: 0.875em;
}
.inquiry .inquiry_inner .enquiry input[type=email]{
    padding: 20px 20px;
    font-size: 0.875em;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: none;
    outline: 0;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.inquiry .inquiry_inner .enquiry input[type=submit]{
    clear: both;
    display: block;
    padding: 20px 60px;
    font-size: 0.875em;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    background-color: #603d99;
    border: none;
    outline: 0;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 auto;
    font-weight:500;
}
.inquiry .inquiry_inner .enquiry .name{
    float: left;
    width: 47%;
} 
.inquiry .inquiry_inner .enquiry .email {
    float: left;
    clear: both;
    margin-top: 28px;
    width: 47%;
}
.inquiry .inquiry_inner .enquiry .phone {
    float: left;
    clear: both;
    width: 47%;
    margin-top: 28px;
    margin-bottom: 28px;
}
.inquiry .inquiry_inner .enquiry textarea {
    width: 50%;
    height: 237px;
    border: 1px solid #4acaa9;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 18px;
    resize: none;
}

@media only screen and (min-width: 1000px){

    @media (hover: hover) {
        .shortcuts:hover{
            width:270px;
            
        }
        .shortcuts:hover span{
            transition-delay: 0.3s;
            opacity:1;
            text-indent: 0;
        }
        .shortcuts:hover a{
            gap: 10px;
        }
    }
}
.shortcuts a{
    display:flex;
    align-items:center;
    color:#fff;
    font-size:22px;
    font-weight:900;
}
@media only screen and (max-width: 1000px){
    .inquiry .inquiry_inner .enquiry .name,
    .inquiry .inquiry_inner .enquiry .email,
    .inquiry .inquiry_inner .enquiry .phone,
    .inquiry .inquiry_inner .enquiry textarea{
        float: none;
        width: 100%;   
    }
    .inquiry .inquiry_inner .enquiry input[type=submit]{
        margin-top: 15px;
        width: 100%;
    }
    .shortcuts{
        display:flex;
        justify-content:center;
        align-items:center;
        position:fixed;
        right:0;
        top:auto;
        border-radius:0;
        bottom:0;
        width:100%;
        height:50px;
        color:#333;
        background-color:var(--main-color);
        z-index:999;
        box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, .2);
    }
    .shortcuts span{
        text-indent: 0;
        opacity:1;
    }
    .shortcuts a{
        gap:10px;
        width:100%;
        height:100%;
        justify-content:center;
    }
    .pc_short{
        display:none;
    }
    .main_info{
        background:#fff;
        padding:35px 0;
    }
    .main_info .main_info_inner_cover{
        flex-direction: column;
    }
    .main_info_txt{
        margin-left: 0;
    }
    .main_info_img{
        max-width: 350px;
        margin: 0 auto;
    }
    .main_info_txt li .title{
        padding-left:0;
    }
    .main_info_txt ul{
        padding: 35px 0;
        
    }
    .main_info_inner2 ul{
        flex-direction: column;
        line-height: 81px;
    }
    .main_info_inner2 ul li{
        width:100%;
        margin-right:0;
        justify-content:center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 45px;
    }

    .main_info_inner2 ul{
        width:100%;
        margin-right:0;
        justify-content:center;
    }

    .main_view{
        flex-direction: column;
    }
    .main_view dd{
        text-align:center;
        margin-left:0;
    }
        
}


@media only screen and (max-width: 650px){
    #main .main_cover .txt_main{
        line-height:normal;
    }
    #main .main_cover .txt_main p.title_main{
        padding-top:20px;
        text-align:center;
        font-size: 18px;
    }
    .main_info_inner{
        padding: 15px 15px;
    }

    .main_info_txt li .title,
    h4.title{
        font-size:16px;
    }

    .main_info_txt li .title::before, .kxSkpo h4.title::before{
        height:14.5px;
    }
    .main_info_txt li{
        font-size: 12px;
    }
    .phone_back{
		display:none;
	}
    .phone_slider{
        margin-left:0;
        margin-top:40px;
    }
    .schedule_box{
        font-size:14px;
    }
    

    .img_info_txt{
        flex-direction: column;
        gap:40px;
    }
    .img_info_txt li{
        width: 100%;
    }
    .img_info_txt dl{
        display:flex;
        align-items:center;
        gap:10px;
    }
    .img_info_txt li dt {
        width: 100px;
        height: 100px;
        margin: 0;
    }
    .img_info_txt li dd{
        text-align:left;
        width: calc((100% - 110px) / 1);
    }
    br.displayNone{
        display:inline;
    }
}

@media only screen and (max-width: 400px){
    h1{
        font-size: 36px;
    }
    .main_view dd h5{
        font-size: 26px;
    }
}

@media only screen and (max-width: 340px){
    .main_info_txt li .title{

        min-width:auto;
        font-size:12px;
    }
    .main_info_txt li .title{
        font-size:12px;
    }
    .main_info .main_info_inner_cover{
        padding:0;
        padding: 30px 5px;
    }
    .main_info .main_info_inner{
        padding:30px 10px;
    }
    .main_info_txt li .title::before{
        display:none;
    }
}
`; 