import { Route, Routes } from "react-router-dom";
import Loading from './templates/sources/Loading';
import { CommonStyleMain_R } from './templates/Home/Styled/CommonRStyle';
import { useEffect, useState } from 'react';
import { Main_R } from './templates/Home/Home';
import './templates/sources/css/reset.css';
import { Inquiry } from "./templates/Inquiry/inquiry";
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // 데이터 로딩을 시뮬레이션하기 위한 setTimeout
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // 3초 후 로딩 상태를 false로 설정
  }, []);

  if (isLoading) {
    return <Loading />; // 로딩 중일 때 로딩 아이콘 표시
  }
  return (
    <>
      <CommonStyleMain_R></CommonStyleMain_R>
      <Routes>
        <Route path="/" element={<Main_R />} />
        {/*<Route path="/inquiry" element={<Inquiry />} />*/}
      </Routes>
    </>
  );
}

export default App;
